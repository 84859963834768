import Box from "../../../common/components/Box";
import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { OURPRODUCT_DATA } from "../../../common/data/SecD3v";
import howitworks from "../../../common/assets/image/secD3v/product-how-it-works.png";
import React from "react";
import OurProductArea, { Col, Row } from "./ourProduct.style";
import Video from "../../../components/video";
const OurProduct = () => {
  return (
    <OurProductArea id="what-is-secd3v">
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="What is secd3v?" />
        </Box>
        <Box className="blockText">
          <Text
            as="p"
            content="A cost-effective, sovereign, IRAP-assessed, isolated tenancy GitLab platform. This service integrates seamlessly into your corporate and enterprise networks, presenting a smarter financial alternative to DIY DevSecOps solutions."
          />
        </Box>
        <Box className="bannerVideo">
          <Video
            videoSrcURL="https://www.youtube.com/embed/moVPpNdgxx4?si=8VT1_x9PMjkyPxwi"
            videoTitle="secd3v"
          />
        </Box>
        <Box className="blockTitle">
          <Heading as="h2" content="How it works" />
        </Box>
        <Box className="blockText">
          <Text
            as="p"
            content="secd3v offers a tenanted, software-as-a-service model on the GitLab platform, featuring secure software patterns, templates, and third-party security tools."
          />
        </Box>

        <Box className="bannerImage">
          <img src={howitworks} alt="SecD3v" />
        </Box>
      </Container>
    </OurProductArea>
  );
};

export default OurProduct;
