import Box from "../../../common/components/Box";
import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { BANNER_DATA } from "../../../common/data/SecD3v";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import Typewriter from "typewriter-effect";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import RegistrationForm from "../../../containers/SecD3v/Register";
import BannerArea, { Col, TypewriterWrapper } from "./banner.style";
// import GlobalStyle, {
//   AppWrapper,
//   ContentWrapper,
//   AppContentWrapper,
// } from "../../../containers/SecD3v/SecD3v.style";
const Banner = () => {
  const { title, text, button, image, tagline } = BANNER_DATA;
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <BannerArea id="banner_section">
      <Container className="Container">
        <Col>
          {/* <Heading as="h2" content={title} /> */}
          <TypewriterWrapper>
            <Typewriter
              options={{
                strings: title,
                autoStart: true,
                loop: false,
              }}
            />
          </TypewriterWrapper>
          <Text as="p" content={text} />
          <Box className="ButtonWrap">
            <Link className="Button" to={button.link}>
              {button.label}
              <Icon size={18} icon={androidArrowForward} />
            </Link>
            {/* <button className="Button" onClick={onOpenModal}>
              {button.label}
            </button> */}
            <Modal open={open} onClose={onCloseModal} center>
              <RegistrationForm />
            </Modal>
          </Box>
        </Col>
      </Container>
      <Box className="bannerImage">
        {image.map(({ src }, index) => (
          <img src={src} alt="" key={`banner-image-key-${index}`} />
        ))}
      </Box>
    </BannerArea>
  );
};

export default Banner;
