import Sticky from "react-stickynode";
import Navbar from "../containers/SecD3v/Navbar";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import SEO from "../components/SEO";
import Banner from "../containers/SecD3v/Banner";
import OurProduct from "../containers/SecD3v/OurProduct";
import KeyBenefits from "../containers/SecD3v/KeyBenefits";
import Footer from "../containers/SecD3v/Footer";
import React from "react";
import RegisterForm from "../containers/SecD3v/RegisterForm";

const SecD3v = () => {
  return (
    <>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar />
        </DrawerProvider>
      </Sticky>
      <SEO title="Home" />
      <Banner />
      <OurProduct />
      <KeyBenefits />
      <RegisterForm />
      <Footer />
    </>
  );
};

export default SecD3v;
